import { Store, Http, IHttpAxiosInstance } from '@gf-tech/utils';

const ResList = {
    size: 0,
    total: 0,
    pages: 0,
    orders: [],
    current: 0,
    records: [],
    countId: '',
    maxLimit: 0,
    hitCount: false,
    searchCount: false,
    optimizeCountSql: false,
};

const store = new Store();

const http: IHttpAxiosInstance = new Http().create();

/**
 * 数字数组去重
 * @param array number[] //数字数组
 * @returns Array
 */
const dedupe = (array: readonly number[]) => {
    return Array.from(new Set(array));
};

/**
 *文件大小转为带单位数据
 * @param val number //文件大小
 * @param fmt
 * @returns string //带单位数据
 */

const unitsList = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];
const b2ValueUnit = (val: number) => {
    let unit = 'KB';
    const recursion = (val: number, unit: number) => {
        let num = val / 1024;
        if (num >= 1024) {
            recursion(num, unit++);
        } else {
            return [num, unit];
        }
    };
    let value = val;
    if (val >= 1024) {
        let item = recursion(val, 0);
        unit = unitsList[item[1]];
        value = item[0];
    }
    return value.toFixed(2) + unit;
};
export { http, store, ResList, dedupe, b2ValueUnit };
