import { Component } from 'react';
import { AppProps } from 'single-spa';
import { TAppConf, TResource, TUser } from '@gf-tech/types';
import { http, store } from './utils';
import { RootRouter } from './components/RootRouter';
import styled from 'styled-components';
type TState = {
    router: TResource;
};
const StyleRoot = styled.div`
    flex: 1;
    width: 0;
    margin: 24px;
    padding: 24px;
    padding-top: 8px;
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    height: calc(100% - 48px);
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > td,
    .ant-table tfoot > tr > th {
        padding: 7px 16px;
    }
`;
export default class Root extends Component<AppProps & TAppConf, TState> {
    constructor(props: AppProps & TAppConf) {
        super(props);

        const appConf = props.appConf;
        store.namespaces(appConf.namespace);
        http.config({ baseURL: appConf.apiHost, store });

        const userInfo: TUser = store.get('userInfo');
        const inurse = userInfo.resList.find(item => item.uriRoute === '/bac');
        this.state = {
            router: inurse,
        };
    }

    render() {
        return (
            <StyleRoot>
                <RootRouter base='/bac' router={this.state.router}></RootRouter>
            </StyleRoot>
        );
    }
}
